<template>
    <div class="flex flex-col w-full">
        <autralis-base-select-comp :loading="loading"
                                   :label="showLabel ? 'Country' : null"
                                   placeholder="Country"
                                   required="required"
                                   :selected="selected"
                                   :error="errorLabel"
                                   @on-change="setCountry"
                                   :options="countries"/>
    </div>
</template>

<script>
import axios from "axios";
import {each} from "lodash";
import BaseSelect from '@/components/base-select'

export default {
    name: "countries-select",
    props: {
        selected: {type: String},
        error: {type: String, default: null},
        showLabel: {type: Boolean, default: true},
        required: {type: Boolean, default: false}
    },
    data() {
        return {
            selectedCountry: '',
            countries: [],
            loading: false,
            fetchError: ''
        }
    },

    computed: {
        errorLabel() {
            if (this.fetchError) {
                return this.fetchError
            }

            if (this.$props.error) {
                return this.$props.error
            }

            return null
        }
    },

    watch: {
        selectedCountry() {
            if (this.selectedCountry !== this.$props.selected) {
                this.setCountry(this.selectedCountry);
            }
        },

        selected(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.selectedCountry = newVal
            }
        }
    },
    methods: {
        setCountry(countryCode) {
            this.$emit('on-country-change', countryCode)
        },
        async fetchCountries() {
            try {
                this.loading = true;
                this.fetchError = '';
                const response = await axios.get('/manager/expert3/xp3/countries/', {withCredentials: true})
                if (response.data.result === 'Ok') {
                    const mappedCountries = []
                    each(response.data.countries, country => mappedCountries.push({value: country.code, text: country.name}))
                    this.$store.commit('master/setCountries', mappedCountries);
                } else {
                    this.fetchError = 'Loading countries failed. Please try again.'
                }
                this.loading = false;
            } catch (err) {
                this.fetchError = 'Loading countries failed. Please try again.'
                this.loading = false;
            }
        }
    },

    created() {
        if (this.$store.getters['master/countries'].length === 0) {
            this.fetchCountries()
        } else {
            this.countries = this.$store.getters['master/countries']
        }
        if (this.$props.selected) {
            this.selectedCountry = this.$props.selected;
        }
    },

    components: {
        'autralis-base-select-comp': BaseSelect
    }
}
</script>

<style scoped>

</style>